import { Controller } from "@hotwired/stimulus"

// Used to create a modal window using the <dialog> tag
// To start you need:
// 1. link_to with `data: { turbo_frame: :modal }`
// 2. In template, wrap all content in "turbo_frame_tag :modal"
// 3. Optional: Add button with `data-action="dialog#close"` for close dialog

// Connects to data-controller="dialog"
export default class extends Controller {
  connect() {
    this.open()
    this.element.addEventListener('click', this.#closeOnBackdropClick.bind(this))
  }

  close() {
    this.element.close()
    this.frameTarget.removeAttribute("src")
    this.frameTarget.innerHTML = ""
  }

  open() {
    this.element.showModal()
  }

  // hide modal on successful form submission
  // data-action="turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.close()
    }
  }

  // If click occurred on <dialog> tag, close dialog
  #closeOnBackdropClick(event) {
    if (event.target === this.element) {
      this.close()
    }
  }
}
