import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="kkm"
export default class extends Controller {
  static values = {
    paymentCardData: Object,
    checkData: Object,
    printData: Object
  }

  connect() {
    console.log('Connected to controller', this.checkDataValue)

    if (Object.keys(this.checkDataValue).length) {
      RegisterCheck(this.checkDataValue)
    }

    // if (Object.keys(this.printDataValue).length) {
    //   PrintDocument(this.printDataValue)
    // }
  }

  payByPaymentCardAsync() {
    PayByPaymentCardAsync(this.paymentCardDataValue)
  }

  registerCheck(event) {
    RegisterCheck(event.params.checkData)
  }

  openShift(event) {
    OpenShift(event.params.openShiftData)
  }

  closeShift(event) {
    Settlement(event.params.settlementData)
    CloseShift(event.params.closeShiftData)
  }

  depositCash(event) {
    const amount = prompt('Введите сумму для внесения:')
    event.params.depositCashData.Amount = parseInt(amount)
    DepositingCash(event.params.depositCashData)
  }

  withdrawCash(event) {
    const amount = prompt('Введите сумму для изъятия:')
    event.params.withdrawCashData.Amount = parseInt(amount)
    PaymentCash(event.params.withdrawCashData)
  }
}
