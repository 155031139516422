import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mini-app--telegram"
export default class extends Controller {
  static values = {
    isNeedBackButton: Boolean
  }
  static targets = [
    "signInForm",
    "telegramData",
  ]

  connect() {
    window.Telegram.WebApp.expand()
    window.Telegram.WebView.postEvent("web_app_set_header_color", () => { }, { color: "#ffffff" })
    this.#setTelegramData()
    // Reset Viewport after Mobile Keyboard hide
    window.Telegram.WebView.onEvent("viewport_changed", () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    })
    if (window.Telegram.WebApp.initDataUnsafe.start_param) this.#redirectThroughDeepLink()
  }

  requestContact() {
    window.Telegram.WebApp.requestContact(this.#onContactRequested.bind(this))
  }

  #setTelegramData() {
    if (this.hasTelegramDataTarget) {
      this.telegramDataTarget.value = window.Telegram.WebApp.initData
    }
  }

  #onContactRequested(isUserSentContact, user) {
    if (!isUserSentContact) return
    this.telegramDataTarget.value = user.response
    this.signInFormTarget.requestSubmit()
  }

  #redirectThroughDeepLink() {
    const path = window.Telegram.WebApp.initDataUnsafe.start_param.replaceAll('_', '/')
    if (window.location.href.includes(path)) return
    Turbo.visit('/mini_app/' + path)
  }
}
