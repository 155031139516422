import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mini-app--auto-click"
export default class extends Controller {
  connect() {
    if (confirm(`Открыть ${this.element.href}?`)) {
      window.Telegram.WebApp.openLink(this.element.href)
    }
  }
}
